import { CenteredLoader } from 'components/Loader/Loader'
import Select from 'components/Select/SelectV2'
import { isArray } from 'lodash'

export const MAX_KNOWLEDGE_SOURCES = 10

export const KnowledgeSourceSelect = ({
  onChange,
  value,
  validationError,
  sourceOptions,
  loading,
}: {
  onChange: (selectedSources: number[]) => void
  value: number[]
  validationError?: string
  sourceOptions?: { label: string; value: number }[]
  loading?: boolean
}) => {
  if (loading) {
    return (
      <div className="d-flex flex-row align-center height-50px">
        <CenteredLoader className="w-100 " />
      </div>
    )
  }

  const filterSources = (option: { label: string }, query: string) => {
    return option.label.toLowerCase().includes(query.toLowerCase())
  }

  return (
    <div>
      {sourceOptions && (
        <>
          <Select<{ label: string; value: number }>
            options={sourceOptions}
            menuPlacement="bottom"
            className="h-100"
            placeholder="Restrict search to..."
            explicitPreventOverlap={true}
            defaultValue={sourceOptions.filter(
              option => value && value.includes(option.value)
            )}
            filterOption={filterSources}
            onChange={values => {
              if (isArray(values)) {
                onChange(values.map(value => value.value))
              }
            }}
            isMulti
          />
          {validationError && (
            <div className="text-danger mt-2 fs-small">{validationError}</div>
          )}
        </>
      )}
    </div>
  )
}
